import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'

export const SubscriptionDiscountLabel = (): JSX.Element => (
  <div className="label-success d-inline-block">
    <IconWithText
      text="z subskrypcją dodatkowe 5% rabatu"
      iconClassName="uil-check font-size-xxl lh-initial me-1"
      wrapperClassName="text-success me-auto"
      textClassName="font-size-sm lh-1"
    />
  </div>
)

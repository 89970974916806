import * as React from 'react'
import * as clsx from 'clsx'
import { PriceInformation } from '@hooks/use-price-information'
import { isBlackWeekActive } from '@components/discounts/utils'

interface Props {
  wrapperClassName?: string
  priceInformation: PriceInformation
}

export const ApartmentCardPrice = ({ wrapperClassName, priceInformation }: Props): JSX.Element => {
  return (
    <div className={clsx('apartment-card__price__wrapper-base', wrapperClassName)}>
      {priceInformation.percentageDiscount && (
        <div className={clsx('apartment-card__price__discount__percentage', { 'is-black-week': isBlackWeekActive() })}>
          rabat -{priceInformation.percentageDiscount}
        </div>
      )}
      <strong className="d-block apartment-card__price">{priceInformation.pricePerGuest}</strong>
      {priceInformation.percentageDiscount && (
        <small className="opacity-75">
          zamiast{' '}
          <small className="apartment-card__price__discount__text">
            {priceInformation.pricePerGuestBeforeDiscount}
          </small>
        </small>
      )}
      <strong className="text-secondary d-block font-size-xs apartment-card__price__per-person">doba za osobę</strong>
      <span className="text-muted font-size-md">przy {priceInformation.guestAmount} osobach w lokalu</span>
    </div>
  )
}

import { useDispatch, useSelector } from 'react-redux'
import { useApiRequest } from '@hooks/use-form-request'
import * as React from 'react'
import { fetchAvailableApartments } from '@requests/reservation/reservation-requests'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { selectClientActiveSubscriptionContract } from '@store/selectors/client-details-selectors'

interface Response {
  isLoading: boolean
}

export const useApartments = (): Response => {
  const reservation = useSelector(selectReservation)
  const activeSubscriptionContract = useSelector(selectClientActiveSubscriptionContract)
  const dispatch = useDispatch()

  const { handleAction, isLoading } = useApiRequest()

  React.useEffect(() => {
    handleAction(async () => {
      if (!reservation.accommodation_type_id || !reservation.resort_id) return

      await dispatch(
        fetchAvailableApartments({
          resort: reservation.resort_id,
          date_from: reservation.date_from,
          date_to: reservation.date_to,
          code: reservation.promocode?.code,
          accommodation_type: reservation.accommodation_type_id,
        }),
      )
    })
  }, [
    reservation.resort_id,
    reservation.accommodation_type_id,
    reservation.date_to,
    reservation.date_from,
    reservation.promocode?.code,
    activeSubscriptionContract,
  ])

  return {
    isLoading,
  }
}

import * as React from 'react'
import * as clsx from 'clsx'

const footerAwards = [
  { logo: 'luxury_brand', title: 'Luksusowa marka roku 2021' },
  { logo: 'luxury_product', title: 'Luksusowy produkt roku 2021' },
  { logo: 'highest_quality', title: 'Najwyższa jakość roku 2021' },
]

interface Props {
  wrapperClassName?: string
  singleAwardClassName?: string
}

export const FooterAwards = ({ wrapperClassName, singleAwardClassName }: Props): JSX.Element => (
  <div className={clsx('d-flex', wrapperClassName)}>
    {footerAwards.map(award => (
      <SingleAward key={award.title} logo={award.logo} title={award.title} wrapperClassName={singleAwardClassName} />
    ))}
  </div>
)

interface SingleAwardProps {
  title: JSX.Element | string
  logo: string
  wrapperClassName?: string
}

const SingleAward = ({ title, logo, wrapperClassName }: SingleAwardProps) => (
  <div className={clsx('d-flex', wrapperClassName)}>
    <img
      src={require(`@assets/images/icons/${logo}.png`)}
      alt="Logo Holidaypark"
      height={39}
      width={39}
      loading="lazy"
    />
    <span className="text-muted lh-initial w-75 ms-3 ms-xl-0 mt-xl-3">{title}</span>
  </div>
)

import * as React from 'react'
import { useModal } from '@modals/use-modal'
import { ModalTypes } from '@modals/types'
import * as clsx from 'clsx'

type DiscountType = 'black-week' | 'christmas-discount'

interface Props {
  text: React.ReactNode
  date: string
  modal: ModalTypes
  image: string
  discountType?: DiscountType
}

export const DiscountBanner = ({ text, date, modal, image, discountType = 'black-week' }: Props): JSX.Element => {
  const [showModal] = useModal(modal)

  React.useEffect(() => {
    showModal()
  }, [])

  return (
    <div className={clsx('black-week__banner', `is-${discountType}`)}>
      <div className="container d-flex flex-sm-row flex-column align-items-center justify-content-between">
        <div className="d-flex flex-sm-row flex-column">
          <h1 className="font-size-xxl mb-0 pe-sm-5 lh-sm text-center text-sm-start">{text}</h1>
          <strong className="font-size-xl lh-1 text-center text-sm-start my-1 my-sm-0">
            Wszystkie rezerwacje <img src={image} alt="Promocje w Holiday Park & Resort" className="mx-2" /> taniej*
          </strong>
        </div>
        <div className="font-size-xl">
          <span>Utworzone w terminie </span>
          <strong>{date}</strong>
        </div>
      </div>
    </div>
  )
}

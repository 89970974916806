import * as React from 'react'

interface Props {
  onClose: () => void
  image: string
  textSlideLength: number
  textSlideDelay: number
  text: React.ReactNode
}

export const DiscountModalBase = ({ onClose, image, textSlideDelay, textSlideLength, text }: Props): JSX.Element => (
  <div>
    {Array.from({ length: textSlideLength }, (_, i) => (
      <span
        className="black-week__modal-image__slide-show"
        key={i}
        style={{
          animationDelay: `${i * textSlideDelay}s`,
        }}
      >
        {text}
      </span>
    ))}
    <img className="black-week__modal-image rounded" src={image} alt="Holiday Park & Resort Promocje" />
    <button className="black-week__modal-image__close-button" onClick={onClose}>
      <i className="uil-times" />
    </button>
  </div>
)

import * as React from 'react'
import { AccommodationTypeAvailable } from '@models/reservation'
import { useSelector } from 'react-redux'
import { selectResortAccommodationTypes } from '@store/selectors/app-data-selectors'
import { selectAccommodationTypesAvailable } from '@store/selectors/reservation-selectors'
import { AccommodationType, Resort } from '@models/app-data'

enum AccommodationTypeID {
  House = 1,
  HouseWithGarden = 4,
  APARTMENT40 = 2,
  APARTMENT55 = 3,
}

const accommodationTypesOrder = {
  [AccommodationTypeID.House]: 1,
  [AccommodationTypeID.HouseWithGarden]: 2,
  [AccommodationTypeID.APARTMENT40]: 3,
  [AccommodationTypeID.APARTMENT55]: 4,
}

const byOrder = (a, b) => accommodationTypesOrder[a.id] - accommodationTypesOrder[b.id]

interface Response {
  accommodationTypes: AccommodationType[]
  getAccommodationTypeById: (accommodationTypeAvailableId: number) => AccommodationTypeAvailable | undefined
}

export const useSortedAccommodationTypes = (resort: Resort): Response => {
  const resortAccommodationTypes = useSelector(selectResortAccommodationTypes)(resort)
  const accommodationTypeAvailable = useSelector(selectAccommodationTypesAvailable)

  const getAccommodationTypeById = (accommodationTypeId: number) =>
    accommodationTypeAvailable.find(
      (accommodationType: AccommodationTypeAvailable) =>
        accommodationTypeId === accommodationType.accommodation_type_id && accommodationType.resort_id === resort.id,
    )

  const { available, unavailable } = React.useMemo(
    () =>
      resortAccommodationTypes.reduce(
        (acc, curr) => {
          const isAvailable = getAccommodationTypeById(curr.id)?.available
          return isAvailable
            ? { unavailable: acc.unavailable, available: [...acc.available, curr] }
            : { unavailable: [...acc.unavailable, curr], available: acc.available }
        },
        { available: [], unavailable: [] },
      ),
    [resortAccommodationTypes],
  )

  const sortedResortAccommodationTypes = [...available.sort(byOrder), ...unavailable.sort(byOrder)]

  return {
    accommodationTypes: sortedResortAccommodationTypes,
    getAccommodationTypeById,
  }
}

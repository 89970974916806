import * as React from 'react'
import { isBlackWeekExtensionActive } from '@components/discounts/utils'
import { DiscountBanner } from '@components/discounts/discount-banner'

export const BlackWeekBanner = (): JSX.Element => {
  const isExtensionActive = isBlackWeekExtensionActive()

  return (
    <DiscountBanner
      modal="BlackWeekModal"
      date={isExtensionActive ? 'od 02.12 do 09.12' : 'od 25.11 do 01.12'}
      image={require('@assets/images/black-week-discount.webp')}
      text={
        <>
          {isExtensionActive && <span className="text-danger fw-semi-bold">Przedłużamy </span>}
          <span style={{ color: '#EED478' }}>Black Week</span>{' '}
          <span className="text-nowrap">w Holiday Park & Resort!</span>
        </>
      }
    />
  )
}

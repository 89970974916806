import { endOfDay, isAfter, isBefore, startOfDay } from 'date-fns'

export const isBlackWeekActive = (): boolean => {
  const now = new Date()
  const activeFrom = startOfDay(new Date('2024-11-25'))
  const activeTo = endOfDay(new Date('2024-12-09'))

  return isAfter(now, activeFrom) && isBefore(now, activeTo)
}

export const isBlackWeekExtensionActive = (): boolean => {
  const now = new Date()
  const activeFrom = startOfDay(new Date('2024-12-02'))
  const activeTo = endOfDay(new Date('2024-12-09'))

  return isAfter(now, activeFrom) && isBefore(now, activeTo)
}

export const isChristmasDiscountActive = (): boolean => {
  const now = new Date()
  const activeFrom = startOfDay(new Date('2024-12-10'))
  const activeTo = endOfDay(new Date('2024-12-23'))

  return isAfter(now, activeFrom) && isBefore(now, activeTo)
}

import * as React from 'react'
import { BaseModalProps } from '@modals/types'
import { DiscountModalBase } from '@components/discounts/discount-modal'

export const ChristmasDiscountModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => (
  <DiscountModalBase
    textSlideLength={5}
    textSlideDelay={1.6}
    onClose={toggleIsVisible}
    text={<span className="text-danger">OFERTA ŚWIĄTECZNA </span>}
    image={require('@assets/images/christmas-discount-bg.webp')}
  />
)

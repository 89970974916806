import * as React from 'react'
import { AccommodationType, Resort } from '@models/app-data'
import { ApartmentCard } from '@modules/accommodation-selection-step/local-selection/apartment-card'
import { useSelector } from 'react-redux'
import { selectIsLoaderVisible } from '@store/selectors/loader-selectors'
import { ContentLoader } from '@components/loaders/content-loader'
import { useSortedAccommodationTypes } from '@modules/accommodation-selection-step/local-selection/use-sorted-accommodation-types'

interface Props {
  resort: Resort
}

export const CollapsableResortContent = ({ resort }: Props): JSX.Element => {
  const { accommodationTypes, getAccommodationTypeById } = useSortedAccommodationTypes(resort)

  const isLoading = useSelector(selectIsLoaderVisible)

  return (
    <ContentLoader isLoading={isLoading}>
      <div className="px-3 px-xl-5">
        <h4 className="mb-3 text-secondary fw-bold pt-4">Wybierz typ lokalu:</h4>
        <div className="d-flex flex-column">
          {accommodationTypes.map((accommodationType: AccommodationType) => (
            <ApartmentCard
              key={accommodationType.id}
              accommodationType={accommodationType}
              resort={resort}
              accommodationTypeAvailable={getAccommodationTypeById(accommodationType.id)}
            />
          ))}
        </div>
      </div>
    </ContentLoader>
  )
}

import * as React from 'react'
import { DiscountBanner } from '@components/discounts/discount-banner'

export const ChristmasDiscountBanner = (): JSX.Element => (
  <DiscountBanner
    discountType="christmas-discount"
    image={require('@assets/images/black-week-discount.webp')}
    modal="ChristmasDiscountModal"
    date="od 10.12 do 23.12"
    text={
      <>
        <span className="fw-semi-bold" style={{ color: '#EED478' }}>
          Oferta Świąteczna{' '}
        </span>
        <span className="text-nowrap">w Holiday Park & Resort!</span>
      </>
    }
  />
)

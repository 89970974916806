import * as React from 'react'
import * as clsx from 'clsx'
import { ApartmentSpecifications } from '@modules/accommodation-selection-step/local-selection/apartment-specifications'
import { ApartmentCardPrice } from '@modules/accommodation-selection-step/local-selection/apartment-card-price'
import { AccommodationType } from '@models/app-data'
import { getAccommodationTypeName } from '@helpers/utils'
import { PriceInformation } from '@hooks/use-price-information'
import { ApartmentCardLabels } from '@modules/accommodation-selection-step/local-selection/apartment-card-labels'

interface Props {
  withApartmentTitle?: boolean
  accommodationType: AccommodationType
  priceInformation: PriceInformation
  wrapperClassName?: string
}

export const ApartmentDescription = ({
  withApartmentTitle = true,
  accommodationType,
  priceInformation,
  wrapperClassName,
}: Props): JSX.Element => (
  <>
    {withApartmentTitle && (
      <div className="d-flex flex-column flex-sm-row flex-wrap justify-content-between px-2">
        <h3 className="text-secondary fw-bold d-block mb-3 card__title me-2">
          {getAccommodationTypeName(accommodationType)}
        </h3>
        <ApartmentCardLabels />
      </div>
    )}
    <div className={clsx('container-lg', wrapperClassName)}>
      {withApartmentTitle && (
        <ApartmentCardPrice
          priceInformation={priceInformation}
          wrapperClassName="apartment-card__price__wrapper-inside-card mt-2"
        />
      )}
      <div>
        <span className="d-block text-muted"> {accommodationType.description}</span>
        <ApartmentSpecifications attributes={accommodationType.attributes} />
      </div>
    </div>
  </>
)

import * as React from 'react'
import * as clsx from 'clsx'
import { Gallery } from '@components/gallery'
import { Card } from '@components/card/card'
import { ApartmentDescription } from '@modules/accommodation-selection-step/local-selection/apartment-description'
import { ApartmentCardPrice } from '@modules/accommodation-selection-step/local-selection/apartment-card-price'
import { AccommodationType, Resort } from '@models/app-data'
import { useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { useResponsivenessImages } from '@hooks/use-responsiveness-images'
import { OccupiedBanner } from '@modules/accommodation-selection-step/local-selection/occupied-banner/occupied-banner'
import { usePriceInformation } from '@hooks/use-price-information'
import { useApartmentSelection } from '@modules/accommodation-selection-step/local-selection/apartment-details/use-apartment-selection'
import { ApartmentCardActions } from '@modules/accommodation-selection-step/local-selection/apartment-card-actions'
import { getAccommodationTypeName } from '@helpers/utils'
import { AccommodationTypeAvailable } from '@models/reservation'
import { CardBannerWrapper } from '@modules/accommodation-selection-step/local-selection/card-banner-wrapper'
import { OccupiedBannerLocalForClientWithBenefit } from '@modules/accommodation-selection-step/local-selection/occupied-banner/occupied-banner-local-for-client-with-benefit'

interface Props {
  accommodationType: AccommodationType
  resort: Resort
  accommodationTypeAvailable: AccommodationTypeAvailable | undefined
}

export const ApartmentCard = ({ accommodationType, resort, accommodationTypeAvailable }: Props): JSX.Element => {
  const reservation = useSelector(selectReservation)

  const { isApartmentSelected, handleApartmentSelect } = useApartmentSelection(accommodationType, resort)

  const images = useResponsivenessImages(accommodationType.accommodation_type_images, 'xs')

  const priceInformation = usePriceInformation(accommodationType.display_max_guests, accommodationTypeAvailable)

  const isOccupied = !accommodationTypeAvailable?.available

  const hasApartmentForClientWithBenefit = accommodationTypeAvailable?.has_apartment_for_client_with_benefit

  return (
    <div className="position-relative mb-4" data-testid="apartment-card">
      <Card
        isActive={isApartmentSelected}
        onSelectionIndicatorClick={handleApartmentSelect}
        image={
          <div className="position-relative apartment-card__gallery">
            <ApartmentCardPrice
              priceInformation={priceInformation}
              wrapperClassName="apartment-card__price__wrapper-inside-gallery"
            />
            <Gallery items={images} additionalClass="image-gallery--rounded-lg-left" />
          </div>
        }
        cardActions={
          <ApartmentCardActions
            isApartmentSelected={isApartmentSelected}
            onApartmentSelect={handleApartmentSelect}
            accommodationType={accommodationType}
            resort={resort}
            priceInformation={priceInformation}
          />
        }
        className={clsx(
          'apartment-card',
          { 'is-disabled': !isApartmentSelected && !!reservation.accommodation_type_id },
          { 'is-occupied': isOccupied },
        )}
      >
        <ApartmentDescription
          accommodationType={accommodationType}
          priceInformation={priceInformation}
          wrapperClassName="apartment-card__description"
        />
      </Card>

      {reservation.promocode && !reservation.promocode.accommodation_types?.includes(accommodationType.id) ? (
        <CardBannerWrapper>
          <p className="my-3 text-white fw-semi-bold">
            Rezerwacja lokalu w typie{' '}
            <strong className="text-nowrap">{getAccommodationTypeName(accommodationType)}</strong> nie jest dostępna z
            użyciem wskazanego kodu.
          </p>
        </CardBannerWrapper>
      ) : (
        isOccupied && (
          <OccupiedBanner
            resort={resort}
            title={getAccommodationTypeName(accommodationType)}
            accommodationTypeAvailable={accommodationTypeAvailable ? [accommodationTypeAvailable] : []}
            accommodation={getAccommodationTypeName(accommodationType)}
            wrapperClassName={clsx({ 'is-with-bottom-banner': hasApartmentForClientWithBenefit })}
          >
            {hasApartmentForClientWithBenefit && (
              <OccupiedBannerLocalForClientWithBenefit accommodationType={accommodationType} resort={resort} />
            )}
          </OccupiedBanner>
        )
      )}
    </div>
  )
}

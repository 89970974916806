import * as React from 'react'
import { BaseModalProps } from '@modals/types'
import { isBlackWeekExtensionActive } from '@components/discounts/utils'
import { DiscountModalBase } from '@components/discounts/discount-modal'

export const BlackWeekModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => {
  const isExtensionActive = isBlackWeekExtensionActive()

  return (
    <DiscountModalBase
      textSlideLength={isExtensionActive ? 5 : 8}
      textSlideDelay={isExtensionActive ? 1.6 : 1}
      onClose={toggleIsVisible}
      text={
        <>
          {isExtensionActive && <span className="text-danger">PRZEDŁUŻAMY </span>}
          BLACK WEEK
        </>
      }
      image={
        isExtensionActive
          ? require('@assets/images/black-week-modal-bg-extension.webp')
          : require('@assets/images/black-week-modal-bg.webp')
      }
    />
  )
}

import * as React from 'react'
import * as clsx from 'clsx'
import { Header } from '@modules/header/header'
import { SafetyBanner } from '@components/banners/safety-banner'
import { CollapsableInformation } from '@components/collapsable-information'
import { Footer } from '@modules/footer/footer'
import { useSelector } from 'react-redux'
import { selectTextBlocks } from '@store/selectors/app-data-selectors'
import { useSteps } from '@hooks/use-steps'
import { StepButtonsCard } from '@components/steps/step-buttons-card'
import { GeneralLinks } from '@modules/footer/general-links'
import { FooterPaymentProviders } from '@modules/footer/footer-payment-providers'
import { SingleFooterInformation } from '@modules/footer/single-footer-information'
import { TextBlock } from '@models/app-data'
import { ContactInformation } from '@components/contact-information'
import { FooterAwards } from '@modules/footer/footer-elements/footer-awards'
import { ReservationWebSocketHandler } from '@modules/web-socket-handler'
import { selectIsNextStepActive } from '@store/selectors/steps-selectors'
import { StepFloatingButton } from '@components/steps/step-floating-button'
import { Aside } from '@modules/aside/aside'
import { LoginBanner } from '@modules/header/login/login-banner'
import { GeneralDiscount } from '@components/discounts/general-discount'

enum CollapsableInformationKeys {
  Questions = 'questions',
  Privacy = 'privacy',
  Payment = 'payment',
  Awards = 'awards',
  Login = 'login',
}

export interface MainLayoutCollapsableInformation extends TextBlock {
  key: CollapsableInformationKeys
  title: string
}

interface Props {
  children: JSX.Element | JSX.Element[]
}

export const MainLayout = ({ children }: Props): JSX.Element => {
  const isNextStepButtonActive = useSelector(selectIsNextStepActive)
  const { payment, questions, privacy, awards } = useSelector(selectTextBlocks)

  const { hasNext, goNext, nextName, isLoading } = useSteps()

  const information: MainLayoutCollapsableInformation[] = [
    { ...questions, title: questions.name, content: questions.content, key: CollapsableInformationKeys.Questions },
    { ...privacy, title: privacy.name, content: privacy.content, key: CollapsableInformationKeys.Privacy },
    { ...payment, title: payment.name, content: payment.content, key: CollapsableInformationKeys.Payment },
    { ...awards, title: awards.name, content: awards.content, key: CollapsableInformationKeys.Awards },
  ]

  const isNextStepButtonVisible = isNextStepButtonActive && hasNext

  return (
    <div>
      <ReservationWebSocketHandler />
      <GeneralDiscount />
      <Header />
      <div className="d-flex flex-column flex-xl-row px-0 container-lg">
        <div className="pe-xl-4 w-100 z-index-5">
          <div className="position-relative z-index-5">{children}</div>
          <StepButtonsCard />
        </div>
        <Aside />
      </div>
      <div className={clsx('footer__wrapper', { 'pb-5': isNextStepButtonVisible })}>
        <SafetyBanner
          wrapperClassName="mt-md-4 mt-2 py-4 container-lg px-lg-0 mt-xl-0 py-xl-0 d-xl-none "
          className="is-right-aligned"
        />
        <CollapsableInformation
          collapsableItemTitleWrapperClassName="px-3"
          wrapperClassName="pt-2 d-block d-xl-none"
          infos={information}
          contentRenderer={globalInformationContentRenderer}
        />
        <Footer />
      </div>
      <StepFloatingButton
        isLoading={isLoading}
        isVisible={isNextStepButtonVisible}
        goNext={goNext}
        nextName={nextName}
      />
      <LoginBanner />
    </div>
  )
}

const globalInformationContentRenderer = (data: MainLayoutCollapsableInformation) => informationMapper(data)[data.key]

const informationMapper = (data: MainLayoutCollapsableInformation) => ({
  [CollapsableInformationKeys.Questions]: <ContactInformation wrapperClassName="border-top p-3" />,
  [CollapsableInformationKeys.Privacy]: (
    <GeneralLinks
      wrapperClassName="d-flex flex-column bg-light-azure gap-0"
      linkClassName="font-size-md py-3 fw-bold border-top px-3"
    />
  ),
  [CollapsableInformationKeys.Payment]: (
    <div className="px-3 border-top">
      <SingleFooterInformation
        icon="uil-shield-check lh-1"
        title={data.name}
        subtitle={data.subtitle}
        wrapperClassName="pt-3"
      >
        {data.content}
      </SingleFooterInformation>
      <FooterPaymentProviders
        wrapperClassName="mt-2 bg-light-azure"
        iconsWrapperClassName="flex-wrap justify-content-start"
      />
    </div>
  ),
  [CollapsableInformationKeys.Awards]: (
    <FooterAwards
      wrapperClassName="d-flex flex-column pb-3"
      singleAwardClassName="flex-row fw-bold align-items-center py-2 border-top px-3 font-size-md"
    />
  ),
})
